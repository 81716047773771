var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
define(["require", "exports", "@sentry/browser", "@sentry/tracing", "./hats.polyfill.js"], function (require, exports, Sentry, tracing_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    Sentry = __importStar(Sentry);
    function beforeSend(event) {
        if (HatsConstants.dbg) {
            loudLogging(event);
            return null;
        }
        if (document.location && document.location.protocol === "file:") {
            return null;
        }
        return event;
    }
    var sentryOptions = {
        ignoreErrors: [
            "originalCreateNotification",
            "ckibcdccnfeookdmbahgiakhnjcddpki",
            "pkbffbhglicfngmppdlpmpblfgnkdgio",
            "ResizeObserver loop limit exceeded",
            "There is no clipping info for given tab",
            "gtag is not defined",
            "port object",
            "connecting to extension",
            "NPObject",
        ],
        blacklistUrls: [
            /www\.googletagmanager\.com/i,
            /graph\.facebook\.com/i,
            /connect\.facebook\.net/i,
            /js\.intercomcdn\.com/i,
            /cdn\.segment\.com/i,
        ],
        release: HatsConstants.version,
        environment: HatsConstants.environment,
        dsn: HatsConstants.logging_dsn,
        debug: false,
        integrations: [new tracing_1.Integrations.BrowserTracing()],
        tracesSampleRate: 0.1,
        beforeSend: beforeSend,
    };
    function loudLogging(event) {
        $.notify("Javascript Error Logged! See Console");
        console.log("Error Logged:");
        console.log(event.exception);
        console.log("Request was:");
        console.log(event.request);
        console.log("Extra data was:");
        console.log(event.extra);
        return null;
    }
    Sentry.init(sentryOptions);
    Sentry.configureScope(function (scope) {
        scope.setLevel(Sentry.Severity.Error);
        if (HatsConstants.user.username.length > 0) {
            scope.setUser({ username: HatsConstants.user.username });
        }
    });
    function getCookie(name) {
        if (document.cookie && document.cookie !== "") {
            var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
                var cookie = jQuery.trim(cookies[i]);
                if (cookie.substring(0, name.length + 1) === name + "=") {
                    return decodeURIComponent(cookie.substring(name.length + 1));
                }
            }
        }
        return null;
    }
    $(document).on("submit", "form", function () {
        var $form = $(this);
        var $tokenField = $form.find('[name="csrfmiddlewaretoken"]');
        if ($tokenField.length > 0) {
            var csrfCookie = getCookie("csrftoken");
            if (csrfCookie && $tokenField.val() != csrfCookie) {
                $tokenField.val(csrfCookie);
            }
        }
    });
});
